<template>

</template>
<script>
import api from '../assets/api.js'
import { EventBus } from '../EventBus.js';
import { mapGetters, mapActions } from 'vuex';
import { isValidPhoneNumber } from 'libphonenumber-js';
import moment from 'moment'; 

export default {
    name: 'PhoneRoute',
    data() {
        return {

        };
    },
    created() {
        const phone = this.extractTokenFromURL();
        this.openContactdetails(phone);

    },
    computed: {
        ...mapGetters({
            properties: "propertiesStore/filtered",
            getUserData: 'authenticationStore/getUserData'
        })
    },
    methods: {
        ...mapActions({
            addRoutePhoneNumber : 'onBoardingStore/addRoutePhoneNumber'
        }),
        extractTokenFromURL() {
            const url = window.location.href;
            const phone = url.match(/\/phone\/([^/]+)/);
            if (phone && phone[1]) {
                return phone[1]?.split('?')[0];
            }
            return null;
        },
        extractCalledFromURL(){
            const url = window.location.href;
            const urlObj = new URL(url);
            const calledNumber = urlObj.searchParams.get('called');
            return calledNumber || null;
        },
        validateAndFormatPhone(phoneNumber) {
            if (!phoneNumber) return false;
            let formatted = phoneNumber;
            if (!formatted.startsWith('+')) {
                formatted = '+' + formatted;
            }
            return isValidPhoneNumber(formatted) ? formatted : false;
        },
        async getPropertiesSource(calledNumber){
            const propertySource =  await api.get(this, api.PROPERTIES + 'property-source?called=' + calledNumber);
            return propertySource?.facility;
        },
        async sendPhoneCall (propertyId,contactId,source) {
            if(propertyId && contactId){
                try {
                    let content = `<div class="hb-communication-font-caption"><span class="font-weight-medium">Property: </span><span  class="hb-communication-text-night-light">${this.properties.find(p => p.id === propertyId)?.name}</span></div>`;   
                    if(source){
                        content += `<div class="hb-communication-font-caption"><span class="font-weight-medium">Source: </span><span class="hb-communication-text-night-light">${source}</span></div>`;
                    }
                    content += `<div class="hb-communication-font-caption"><span class="font-weight-medium">Created by: </span><span class="hb-communication-text-night-light">${this.getUserData?.first + ' ' + this.getUserData?.last}</span></div>`;
                    var data = {
                        content: content,
                        method: 'phone_call',
                        created: new Date(),
                        context: 'communicationCenter',
                        pinned: false,
                        property_id: propertyId,
                        direction: 'incoming'
                    }
                    await api.post(this, api.CONTACTS + contactId + '/interaction', data)
                    EventBus.$emit('interaction_saved');
                } catch(err) {
                    console.log("error", err)
                }
            }
        },
        async saveLead(propertyId, phone) {
            if (!propertyId || !phone) {
                throw new Error('Property ID and phone number are required');
            }

            try {
                const payload = {
                    property_id: propertyId,
                    note: "",
                    content: "",
                    source: "",
                    length_of_stay: "",
                    move_in_date: moment().format('YYYY-MM-DD'),
                    rent_as_business: false,
                    lead_type: "inquiry",
                    tracking: {
                        touchpoints: [
                            {
                                referrer_channel: "Offline",
                                referrer_medium: "Phone",
                                referrer_source: "Call Center",
                                platform_source: "Hummingbird"
                            }
                        ]
                    },
                    interaction_initiated: "Phone",
                    source_type: "Call Center",
                    Contact: {
                        first: "New",
                        last: "Lead",
                        company: "",
                        email: "",
                        rent_as_business: false,
                        Phones: [
                            {
                                type: "Cell",
                                phone: phone,
                                sms: true
                            }
                        ],
                        Addresses: [
                            {
                                id: null,
                                type: "Home",
                                address_id: "",
                                Address: {
                                    address: "",
                                    address2: "",
                                    city: "",
                                    neighborhood: "",
                                    lat: "",
                                    lng: "",
                                    state: "",
                                    country: "",
                                    zip: ""
                                }
                            }
                        ]
                    }
                };

                const response = await api.post(this, api.LEADS, payload);
                if (!response || !response.contact_id) {
                    throw new Error('Failed to create lead: Invalid response');
                }

                return response.contact_id;
            } catch (error) {
                console.error('Error saving lead:', error);
                throw error;
            }
        },
        async openContactdetails(phone) {
            this.$store.commit("onBoardingStore/resetLease");
            this.$store.commit("onBoardingStore/resetContact");
            
            if(typeof phone === 'string' && phone.toLowerCase() === 'anonymous') {
              phone = '441865522073';
            }

            const formattedPhone = this.validateAndFormatPhone(phone);
            if (!formattedPhone) {
                this.$router.push('/404');
                return;
            }
            
            const calledNumber = this.extractCalledFromURL();
            if (calledNumber && !this.validateAndFormatPhone(calledNumber)) {
                this.$router.push('/404');
                return;
            }

            let propertyId = null;
            let source = null;
            if (calledNumber) {
                const facility = await this.getPropertiesSource(calledNumber);
                propertyId = facility?.property_id;
                source = facility?.source
            }
            
            try {
                let r = await api.get(this, api.CONTACTS + 'check', {
                    id: undefined,
                    phone: phone
                });
                let contactId = r?.contacts[0]?.id
                if (calledNumber && propertyId && !contactId) {
                   contactId = await this.saveLead(propertyId, phone);
                   await this.sendPhoneCall(propertyId, contactId,source);
                   r =  await api.get(this, api.CONTACTS + 'check', {
                            id: undefined,
                            phone: phone
                        });
                }else if(calledNumber && propertyId && contactId){
                    await this.sendPhoneCall(propertyId, contactId,source);
                }
                let leasesCount = r?.contacts[0]?.Leases?.length
                let contact = r?.contacts[0]

                if (contact.status === 'Pending') {
                    await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === contact?.Pending[0]?.Unit?.property_id));
                    await this.$store.dispatch('onBoardingStore/getPending', { unit_id: contact?.Pending[0]?.Unit?.id });

                    let lead = contact.Leads.find((item) => {
                        return item?.Lease?.id == contact.Pending[0]?.id
                    })
                    var data = {
                        id: lead.id,
                        contact_id: contact.id,
                        lead_first: contact.first,
                        lead_last: contact.last,
                        lease_id: contact?.Pending[0]?.id,
                        unit_id: contact?.Pending[0]?.Unit?.id,
                        selectedLeadId: lead.id
                    }
                    this.$router.push('/leads');
                    EventBus.$emit('HB-Navigation:LeadFollowUp', data);
                } else if (contact.status === 'Active Lead') {
                    if (contact.Reservations.length > 0) {
                        await this.$store.dispatch('onBoardingStore/getPropertyInfo', this.properties.find(p => p.id === contact?.Reservations[0]?.Lease?.Unit?.property_id));

                        let lead = contact.Leads.find((item) => {
                            return item?.lease_id == contact.Reservations[0]?.lease_id
                        })
                        var data = {
                            id: lead?.id,
                            contact_id: contact.id,
                            lead_first: contact.first,
                            lead_last: contact.last,
                            lease_id: contact?.Reservations[0]?.lease_id,
                            reservation_id: contact?.Reservations[0]?.id,
                            reservation_time: contact?.Reservations[0]?.time,
                            unit_id: contact?.Reservations[0]?.Unit?.id,
                            selectedLeadId: lead?.id
                        }
                        this.$router.push('/leads');
                        EventBus.$emit('HB-Navigation:LeadFollowUp', data);
                    } else {
                        var data = {
                            id: contact?.Leads[0]?.id,
                            contact_id: contact.id,
                            lead_first: contact.first,
                            lead_last: contact.last,
                            unit_id: contact?.Leads[0]?.unit_id,
                            selectedLeadId: contact?.Leads[0]?.id
                        }
                        this.$router.push('/leads');
                        EventBus.$emit('HB-Navigation:LeadFollowUp', data);
                    }
                } else if (contact.status === 'Delinquent') {
                    this.$router.push(`/contacts/${contactId}`);
                } else if (contactId && leasesCount > 0) {
                    this.$router.push(`/contacts/${contactId}`);
                } else if (leasesCount === 0 && contact.Leads?.length === 0) {
                    var data = {
                        contact_id: contact.id,
                    }
                    this.$router.push('/leads');
                    EventBus.$emit('HB-Navigation:LeadFollowUp', data);
                } else {
                
                    var data = {
                        phone: phone,
                        isDisabledPhone: true,
                        firstName: 'New',
                        lastName: 'Lead',
                        data:{
                            selectedInteractionOption: 'Phone',
                            selectedSourceOption: 'Call Center'
                        }
                    } 
                    this.$router.push('/leads');
                    EventBus.$emit('new_lead',{location:'create_new_lead'});
                    this.addRoutePhoneNumber(data);
                    
                }
            } catch (error) {
                var data = {
                        phone: phone,
                        isDisabledPhone: true,
                        firstName: 'New',
                        lastName: 'Lead',
                        data:{
                        selectedInteractionOption: 'Phone',
                        selectedSourceOption: 'Call Center'
                    }
                }
                this.$router.push('/leads');
                EventBus.$emit('new_lead',{location:'create_new_lead'});
                this.addRoutePhoneNumber(data);
                console.log("error", error)
            }
        }
    }
};
</script>